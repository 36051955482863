import React from "react";
import "./contact.css";

const About = () =>{

    return(
        <div className="page-alignment">
            <div className="about-us">
                <h2><u>About Byte Forge Solutions</u></h2>
                <p>
                At Byte Forge Solutions, we believe in the fusion of creativity and technology. Founded by passionate individuals with roots in the arts,
                we have made a transition to the world of technology, not by leaving behind our creative origins, but by embracing them.
                </p>
                <p>
                With a unique blend of artistic insight and cutting-edge technological skills, we design, create, and deploy custom technology-based solutions
                tailored to the specific needs of organizations and individuals. Our approach is not just about solving problems; it's about crafting experiences,
                innovating solutions, and bridging the gap between imagination and functionality.
                </p>
                <h3><u>Meet the Founders</u></h3>
                <p>Jake Bigelow and Savannah Yost started their journey in the arts, exploring various creative disciplines and engaging with different forms 
                of expression. Their artistic background has not only provided them with a keen eye for aesthetics but also fostered a unique approach to problem-solving.
                </p>
                <p>
                Their transition into the tech industry was a natural progression, fueled by curiosity, creativity, and the desire to make a tangible impact. 
                They recognized that the same principles that guided their artistic pursuits could also drive innovation in technology.
                </p>
                <p>
                Today, Byte Forge Solutions stands as a testament to their vision and commitment, offering bespoke solutions that transcend traditional tech boundaries.
                </p>
                <h3><u>Our Services</u></h3>
                <ul>
                    <li><b>Custom Software Development:</b> Tailoring software solutions to meet your specific needs and business goals.</li>
                    <li><b>Web & Mobile Application Development:</b> Building seamless and user-friendly interfaces across various platforms.</li>
                    <li><b>Technology Consulting:</b> Leveraging our expertise to guide your technological strategy and implementation.</li>
                    <li><b>Integration & Automation:</b> Connecting and optimizing your existing systems for maximum efficiency.</li>
                </ul>
                <h3><u>Get in Touch</u></h3>

                <span>Are you looking for a personalized technology solution infused with creative thinking? Let's talk. <a href="./contact">Contact us</a> today,
                and let's forge the future together.</span><br/><br/><br/>

            </div>
        </div>

    )
}

export default About