import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Landing from './landing'
import Contact from './contact';
import About from './about';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path = "/" element={<Landing/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/about" element={<About/>}/>
      </Routes>

    </Router>
   
  );
}

export default App;
