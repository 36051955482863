import React from "react";
import {useFormik} from 'formik';
import axios from 'axios';
import { useState } from "react";
import "./contact.css"
import Message from "./message";

const Contact = () =>{

    const [resMessage, setResMessage] = useState('');

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },

        onSubmit: (values) =>{
            axios.post('/contact', values)
            .then(res =>{
                setResMessage(res.data)
            })
            .catch(err =>{
                setResMessage(err.data)
            })
        }
    })
    
    const handleCloseMessage =() =>{
        setResMessage('')
        formik.resetForm();
    }

    return(
        <div className="page-alignment">
             <div className="contact-us">
                <h1><u>Contact Us</u></h1>
            <form onSubmit={formik.handleSubmit}>
                <label htmlFor="name">Name:</label><br/>
                <input type="text" id="name" name="name" onChange={formik.handleChange} value={formik.values.name} required/><br/><br/>
                <label htmlFor="email">Email:</label><br/>
                <input type="email" id="email" name="email" onChange={formik.handleChange} value={formik.values.email} required/><br/><br/>
                <label htmlFor="message">Message:</label><br/>
                <textarea id="message" name="message" onChange={formik.handleChange} value={formik.values.message} required></textarea><br/><br/>
                <button type="submit">Send Messge</button>
            </form><br/>
            </div>
            <Message message={resMessage} onClose={handleCloseMessage}/>
        </div>
    )
}

export default Contact
