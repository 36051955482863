import React from "react";
import './message.css';

function Message({ message, onClose}){
    if(!message) return null;

    return(
        <div className={`message ${message ? 'show':''}`}>
            <div className="message-content">
                {message}
            </div>
            <div className="message-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    )
}

export default Message;